<!-- PÁGINA DE INICIO. VARÍA SEGÚN MÓVIL O DESKTOP -->

<template>
  <Header />
  <Footer />
  <div class="container-margin alignment-container q-mt-xl">
    <div class="container-home">
      <!-- Mobile -->
      <div class="header-desktop">
        <HelloUser />
        <HomeSliderProductsMobile :points="points" />
      </div>
      <InfoPoints />
      <SliderOptions />
    </div>

    <!-- Desktop -->
    <div class="header-mobile">
      <div class="q-ml-md">
        <HelloUser />
      </div>
      <div>
        <HomeSliderProducts :points="points"/>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapActions } from 'vuex';


const Header = defineAsyncComponent(() =>
  import("../../shared/components/Header.vue")
);

const Footer = defineAsyncComponent(() =>
  import("../../shared/components/Footer.vue")
);

const InfoPoints = defineAsyncComponent(() =>
  import("../../shared/components/InfoPoints.vue")
);

const HelloUser = defineAsyncComponent(() =>
  import("../../shared/components/HelloUser.vue")
);

const HomeSliderProducts = defineAsyncComponent(() =>
  import("../components/HomeSliderProducts.vue")
);

const HomeSliderProductsMobile = defineAsyncComponent(() =>
  import("../components/HomeSliderProductsMobile.vue")
);

const SliderOptions = defineAsyncComponent(() =>
  import("../components/SliderOptions.vue")
);

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    InfoPoints,
    HelloUser,
    HomeSliderProducts,
    HomeSliderProductsMobile,
    SliderOptions,
  },
  computed: {
    points() {
      return this.$store.state.points.points;
    },
  },
  methods: {
    ...mapActions("points", ["getPoints"]),
  },
  mounted() {
    this.getPoints();
  },
};
</script>

<style lang="scss" scoped>
.alignment-container {
  @media only screen and (min-width: 765px) {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (min-width: 767px) {
  .header-mobile {
    display: block;
  }

  .header-desktop {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-desktop {
    display: block;
  }

  .header-mobile {
    display: none;
  }
}
</style>
